<template>
  <div>
    <div class="py-6 w-full px-3">
      <h1 class="text-xl text-left font-bold flex mb-2 -mt-2">
        Bank Accounts
      </h1>
      <div class="w-full h-0 p-0 mb-4" style="border: 0.2px solid #d6dbe4" />
      <Tabs
        class="mt-2 mx-2"
        :tabs="tab"
        :active-tab="currentTab"
        :border="true"
        @currentTab="currentTab = $event"
      />
      <direct-debit-accounts
        v-if="currentTab === 'Direct Debit Accounts'"
        :direct-debit-data="directDebitData"
      />
    </div>
  </div>
</template>

<script>
import Tabs from "@scelloo/cloudenly-ui/src/components/tab";
import DirectDebitAccounts from "./DirectDebitAccounts";

export default {
  name: "BankAccounts",
  components: {
    Tabs,
    DirectDebitAccounts
  },
  data() {
    return {
      associationsData: [],
      directDebitData: [],
      currentTab: "Direct Debit Accounts",
      tab: ["Direct Debit Accounts", "Associations"]
    };
  }
};
</script>
